import React, { Component } from 'react';
import styled from 'styled-components';
import logo from './datacake.svg';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import NumberFormat from 'react-number-format';

const Hug = styled.div`
  max-width: 1068px;
  padding: 24px 24px;
  margin: 0 auto;
`;

const Logo = styled.div`
  max-width: 200px;
  margin-left: -7px;
`;

const Title = styled.h1`
  font-size: 3em;
  // font-family: 'Bangers', cursive;
  `;

const StatsRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Amount = styled.div`
  font-size: 2em;
  font-weight: bold;
`;

const Today = styled.div`
  align-self: flex-end;

  ${Amount} {
    font-size: 4em;
  }
`;

const Unit = styled.span`
  font-size: 1.5rem;
`;

const Help = styled.div`
  color: #c2c3c6;
`;

const ToGo = styled.div<{
  success: boolean;
}>`
  align-self: flex-end;
  ${Amount} {
    color: ${props => props.success ? '#009e37' : '#cd1728'};
  }
`;

const CurrentRank = styled.div`
  align-self: flex-end;
`;

const Highscore = styled.table`
  width: 100%;
  margin-top: 48px;
  border-collapse: collapse;

  th {
    text-align: left;
    font-size: .8em;
    font-weight: normal;
    color: #c2c3c6;
    padding-bottom: 3px;
  }

  th:first-child {
    padding-left: 12px;
    color: #cd1728;
  }

  th:last-child {
    text-align: right;
    padding-right: 12px;
  }

  td {
    font-size: 1.5em;
    border-top: 1px solid #5c5d5d;
    padding: 6px 0;
  }

  td:first-child {
    padding-left: 12px;
    color: #cd1728;
    font-weight: bold;
  }

  td:last-child {
    font-size: 2em;
    font-weight: bold;
    text-align: right;
    padding-right: 12px;
  }
`;

const WIRTSHAUS_QUERY = gql`
  query wirtshaus {
    device(deviceId:"3062cd35-903f-4c32-a849-87e7dc97c85c") {
      id
      currentMeasurements(fieldNames:["EC1_0", "EC1_1", "EC1_2", "EC1_3", "EC2_0", "EC2_1", "EC2_3"]) {
        id
        value(timeRangeStart:"2019-02-16 06:00:00", timeRangeEnd:"2019-02-17 06:00:00")
      }
    }
  }
`;

interface Data {
  device: {
    id: string;
    currentMeasurements: {
      id: string;
      value: number;
    }[];
  }
};

class WirtshausQuery extends Query<Data, {}> {};

class App extends Component {
  render() {
    return (
      <Hug>
        <Logo>
          <img src={logo}/>
        </Logo>
        <Title>Klootschmieten</Title>
        <WirtshausQuery
          query={WIRTSHAUS_QUERY}
          pollInterval={10 * 1000}
        >
          {({loading, data}) => {
            if(loading) return <p>Lade Daten...</p>;
            if(data) {
              const total = data.device.currentMeasurements.map(measurement => measurement.value).reduce((a, b) => a + b, 0);
              const veltins = data.device.currentMeasurements.filter((_, index) => [6, 4].indexOf(index) !== -1).map(measurement => measurement.value).reduce((a, b) => a + b, 0);
              const grevensteiner = data.device.currentMeasurements.filter((_, index) => [2].indexOf(index) !== -1).map(measurement => measurement.value).reduce((a, b) => a + b, 0);
              const altbier = data.device.currentMeasurements.filter((_, index) => [5, 3].indexOf(index) !== -1).map(measurement => measurement.value).reduce((a, b) => a + b, 0);
              const weizen = data.device.currentMeasurements.filter((_, index) => [1].indexOf(index) !== -1).map(measurement => measurement.value).reduce((a, b) => a + b, 0);

              const place = total > 900 ? 1 : total > 714 ? 2 : 3;

              const ROWS = [
                {
                  liter: total,
                  row: <tr>
                    <td>2019</td>
                    <td><NumberFormat value={veltins} thousandSeparator="." decimalScale={0} fixedDecimalScale={true} decimalSeparator="," displayType="text"/> Liter</td>
                    <td><NumberFormat value={grevensteiner} thousandSeparator="." decimalScale={0} fixedDecimalScale={true} decimalSeparator="," displayType="text"/> Liter</td>
                    <td><NumberFormat value={altbier} thousandSeparator="." decimalScale={0} fixedDecimalScale={true} decimalSeparator="," displayType="text"/> Liter</td>
                    <td><NumberFormat value={weizen} thousandSeparator="." decimalScale={0} fixedDecimalScale={true} decimalSeparator="," displayType="text"/> Liter</td>
                    <td><NumberFormat value={total} thousandSeparator="." decimalScale={0} fixedDecimalScale={true} decimalSeparator="," displayType="text"/> Liter</td>
                  </tr>,
                },
                {
                  liter: 900,
                  row: <tr>
                    <td>2017</td>
                    <td>900 Liter</td>
                    <td>- Liter</td>
                    <td>- Liter</td>
                    <td>- Liter</td>
                    <td>900 Liter</td>
                  </tr>,
                },
                {
                  liter: 714,
                  row: <tr>
                    <td>2018</td>
                    <td>643 Liter</td>
                    <td>20 Liter</td>
                    <td>51 Liter</td>
                    <td>0 Liter</td>
                    <td>714 Liter</td>
                  </tr>,
                }
              ].sort((a, b) => {
                if(a.liter < b.liter) {
                  return 1;
                }
                if(a.liter > b.liter) {
                  return -1;
                }
                return 0;
              });

              return (
                <React.Fragment>
                  <StatsRow>
                    <Today>
                      <h2>Dieses Jahr</h2>
                      <Amount><NumberFormat value={total} thousandSeparator="." decimalScale={2} fixedDecimalScale={true} decimalSeparator="," displayType="text"/> <Unit>Liter</Unit></Amount>
                      <Help>wurden bereits verzapft</Help>
                    </Today>
                    <ToGo success={900 - total < 0}>
                      <Amount>
                        <NumberFormat allowNegative={false} value={900 - total} thousandSeparator="." decimalScale={2} fixedDecimalScale={true} decimalSeparator="," displayType="text"/> <Unit>Liter</Unit>
                      </Amount>
                      <Help>{900 - total > 0 ? "bis zum Rekord aus 2017" : "seit dem Rekord aus 2017"}</Help>
                    </ToGo>
                    <CurrentRank>
                      <Amount>
                        {place}. <Unit>Platz</Unit>
                      </Amount>
                      <Help>aktueller Platz</Help>
                    </CurrentRank>
                  </StatsRow>

                  <Highscore>
                    <thead>
                      <tr>
                        <th>Jahrgang</th>
                        <th>Veltins</th>
                        <th>Grevensteiner</th>
                        <th>Altbier</th>
                        <th>Weizenbier</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {ROWS.map(({row}) => row)}
                    </tbody>
                  </Highscore>
                </React.Fragment>
              );
            }
            return <p>Error</p>;
          }}
        </WirtshausQuery>
      </Hug>
    );
  }
}

export default App;

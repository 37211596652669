import React from 'react';
import ReactDOM from 'react-dom';
import './klootschmieten.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import ApolloClient from "apollo-client";
import { ApolloProvider } from "react-apollo";
import { setContext } from 'apollo-link-context';
import { createHttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';


const graphql_base_url = 'https://api.datacake.de/graphql/';

const httpLink = createHttpLink({
    uri: graphql_base_url,
});

const authLink = setContext((_, { headers }) => {
    const token = "Token 506b0f7a54c80b84d19ced2e9f30b873ae31c81b"; // WIRtshaus readonly token
    return {
        headers: {
            ...headers,
            authorization: token,
        }
    }
});

const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache()
});

ReactDOM.render(<ApolloProvider client={client}><App /></ApolloProvider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
